/* Case Study Management Container */
.case-study-management {
    padding: 4rem 2rem;
    text-align: center;
    background-color: #f8f9fa;
    min-height: 100vh;
}

    .case-study-management .case-study-management-title {
        font-size: 2.5rem;
        font-weight: bold;
        color: #25d366;
        margin-bottom: 2rem;
    }

    .case-study-management .case-study-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
    }

    .case-study-management .form-group {
        width: 100%;
        max-width: 600px; /* Increased width to be consistent with ToolkitManagement */
        margin-bottom: 1.5rem;
        text-align: left;
    }

        .case-study-management .form-group label {
            display: block;
            margin-bottom: 0.5rem;
            color: #333;
            font-weight: bold;
        }

        .case-study-management .form-group input,
        .case-study-management .form-group textarea {
            width: 100%;
            padding: 0.75rem;
            border: 1px solid #ddd;
            border-radius: 4px;
            box-sizing: border-box;
            font-size: 1rem;
        }

        .case-study-management .form-group textarea {
            resize: vertical;
            height: 120px;
        }

    .case-study-management .checkbox-group {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .case-study-management .case-study-form button {
        padding: 0.75rem 1.5rem;
        background-color: #25d366;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin: 0.5rem;
        width: 100%;
        max-width: 200px;
    }

        .case-study-management .case-study-form button:hover {
            background-color: #1da153;
        }

    /* Case Studies Table */
    .case-study-management .case-studies-table {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        border-collapse: collapse;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

        .case-study-management .case-studies-table th,
        .case-study-management .case-studies-table td {
            padding: 1rem;
            border: 1px solid #ddd;
            text-align: left;
        }

        .case-study-management .case-studies-table th {
            background-color: #25d366;
            color: white;
        }

        .case-study-management .case-studies-table .actions-column {
            display: flex;
            gap: 0.5rem;
        }

        .case-study-management .case-studies-table td button {
            padding: 0.5rem 1rem;
            background-color: #ff0000;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            margin: 0.5rem 0;
            transition: background-color 0.3s ease;
        }

            .case-study-management .case-studies-table td button:hover {
                background-color: #cc0000;
            }

    .case-study-management .open-case-study-button {
        background-color: #25d366;
        margin: 0.5rem 0;
    }

        .case-study-management .open-case-study-button:hover {
            background-color: #1da153;
        }

    /* Loading State */
    .case-study-management .loading {
        font-size: 1.5rem;
        color: #007bff;
    }

    /* File display with remove button */
    .case-study-management .file-display {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 0;
        border-bottom: 1px solid #ddd;
        margin-bottom: 0.5rem;
    }

        .case-study-management .file-display span {
            max-width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: #333;
        }

    .case-study-management .remove-button {
        background-color: #ff4d4d;
        color: white;
        border: none;
        padding: 6px 12px;
        cursor: pointer;
        border-radius: 4px;
        font-size: 14px;
        margin-left: 1rem;
        transition: background-color 0.3s ease;
        flex-shrink: 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

        .case-study-management .remove-button:hover {
            background-color: #e60000;
        }

    .case-study-management .file-container {
        margin-bottom: 1rem;
    }

    .case-study-management .remove-button:focus {
        outline: none;
    }

    /* Popup Styling */
    .case-study-management .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .case-study-management .popup-content {
        background-color: #fff;
        padding: 2rem;
        border-radius: 8px;
        max-width: 800px;
        width: 100%;
        text-align: left;
    }

        .case-study-management .popup-content h3 {
            font-size: 2rem;
            margin-bottom: 1rem;
            color: #25d366;
        }

        .case-study-management .popup-content p {
            margin-bottom: 1.5rem;
            color: #333;
        }

    .case-study-management .file-columns {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
    }

    .case-study-management .file-column {
        width: 45%;
    }

        .case-study-management .file-column h4 {
            font-size: 1.25rem;
            margin-bottom: 1rem;
            color: #333;
        }

        .case-study-management .file-column ul {
            list-style-type: none;
            padding: 0;
        }

            .case-study-management .file-column ul li {
                margin-bottom: 0.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

                .case-study-management .file-column ul li a {
                    text-decoration: none;
                    color: #007bff;
                    transition: color 0.2s ease;
                    max-width: 70%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                    .case-study-management .file-column ul li a:hover {
                        color: #0056b3;
                    }

    .case-study-management .close-popup-button {
        padding: 0.75rem 1.5rem;
        background-color: #ff0000;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        display: inline-block;
    }

        .case-study-management .close-popup-button:hover {
            background-color: #cc0000;
        }

/* Greying out unauthorized case studies */
.unauthorized-case-study {
    opacity: 0.5;
    pointer-events: none; /* Make them unclickable */
}

/* Normal case study item */
.case-study-item {
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

    .case-study-item:hover {
        background-color: #f9f9f9;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

/* Specific styles for document icons and text */
.material-icons.pdf-icon,
.material-icons.supplemental-icon {
    color: #007bff;
    margin-right: 8px;
}

.pdf-documents ul,
.supplemental-documents ul {
    list-style-type: none;
    padding-left: 0;
}

/* Input and button styling for image and tags */
.case-study-management .form-group input[type="file"] {
    margin-bottom: 1rem;
}

.case-study-management .tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.case-study-management .tag {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    gap: 0.3rem;
}

    .case-study-management .tag button {
        background: none;
        border: none;
        color: white;
        cursor: pointer;
    }

        .case-study-management .tag button:hover {
            color: #ff4d4d;
        }
