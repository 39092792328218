.user-detail {
  padding: 2rem;
  background-color: #f9f9f9; /* Softer background color */
  border: 1px solid #ccc; /* Subtle border color */
  border-radius: 10px; /* Slightly larger border-radius */
  margin-top: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
  text-align: left;
}

.user-detail h3, .user-detail h4 {
  margin-bottom: 1rem;
  color: #333; /* Darker text color for headings */
}

.user-detail p {
  margin: 0.5rem 0;
  color: #555; /* Softer text color for paragraphs */
}

.user-detail button {
  margin: 0.5rem;
  padding: 0.6rem 1.2rem; /* Slightly larger padding */
  background-color: #4caf50; /* More vibrant green */
  color: #fff;
  border: none;
  border-radius: 5px; /* Slightly larger border-radius */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.user-detail button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

.user-detail h4 {
  margin-top: 1.5rem;
  color: #444; /* Darker color for subheadings */
  border-bottom: 2px solid #ddd; /* Underline subheadings */
  padding-bottom: 0.5rem;
}

.user-detail input[type="checkbox"] {
  margin-right: 0.75rem; /* Increase spacing between checkbox and label */
}

.user-detail div {
  margin-bottom: 1rem; /* Add spacing between each resource/mlKit */
}

.confirm-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure popup is above other content */
}

.confirm-popup-content {
  text-align: center;
}

.confirm-popup button {
  margin: 0.5rem;
  padding: 0.6rem 1.2rem;
  background-color: #f44336; /* Red color for confirmation */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-popup button:hover {
  background-color: #d32f2f;
}

.confirm-popup button:last-child {
  background-color: #9e9e9e; /* Gray for cancel button */
}

.confirm-popup button:last-child:hover {
  background-color: #757575;
}
