/* Resources page styling */
.resources-page {
    padding: 2rem;
    text-align: center;
    background-color: #f8f9fa;
    min-height: 100vh;
}

.resources-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #25d366;
    margin-bottom: 2rem;
}

.resources-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.resources-btn {
    padding: 0.75rem 1.5rem;
    background-color: #25d366;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

    .resources-btn:hover {
        background-color: #1da153;
    }

    .resources-btn.active {
        background-color: #1da153;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

.resources-content {
    margin-top: 2rem;
}

.resources-section {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
}

    .resources-section h3 {
        font-size: 1.75rem;
        color: #333;
        margin-bottom: 1rem;
    }

    .resources-section ul {
        list-style: none;
        padding: 0;
    }

    .resources-section li {
        margin-bottom: 1.5rem;
    }

    .resources-section a {
        text-decoration: none;
        color: #007bff;
        font-weight: bold;
        transition: color 0.3s ease;
    }

        .resources-section a:hover {
            color: #0056b3;
        }

    .resources-section .description {
        font-size: 0.875rem;
        color: #555;
        margin-top: 0.5rem;
    }

.clickable-tutorial {
    color: #007bff;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
    transition: color 0.3s ease;
}

    .clickable-tutorial:hover {
        color: #0056b3;
    }

/* Tutorial view styling */
.tutorial-view {
    text-align: center;
}

    .tutorial-view h3 {
        font-size: 2rem;
        margin-bottom: 1rem;
        color: #25d366;
    }

.back-btn {
    background-color: #25d366;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    margin-bottom: 1rem;
}

    .back-btn:hover {
        background-color: #1da153;
    }
