.course-details {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 20px auto;
}

    .course-details h2 {
        font-size: 24px;
        color: #333;
        margin-bottom: 16px;
        text-align: center;
    }

    .course-details h3 {
        font-size: 20px;
        color: #555;
        margin-bottom: 12px;
        text-align: center;
    }

    .course-details p {
        font-size: 16px;
        color: #666;
        line-height: 1.5;
        margin: 8px 0;
        text-align: center;
    }

    .course-details ul {
        list-style: none;
        padding: 0;
    }

    .course-details li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 6px;
        padding: 10px 15px;
        margin-bottom: 10px;
    }

    .course-details .toggle-resource-btn {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 6px 12px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .course-details .toggle-resource-btn:hover {
            background-color: #0056b3;
        }

    .course-details .edit-course-btn,
    .course-details .close-button {
        background-color: #28a745;
        color: #fff;
        border: none;
        padding: 10px 20px;
        margin: 5px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .course-details .edit-course-btn:hover {
            background-color: #218838;
        }

    .course-details .close-button {
        background-color: #dc3545;
    }

        .course-details .close-button:hover {
            background-color: #c82333;
        }

.error-message {
    color: #d9534f;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
}

/* Collapsible section buttons */
.toggle-section-btn {
    background-color: #ffeb3b;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    margin: 10px auto;
    display: block;
    transition: background-color 0.3s ease;
}

    .toggle-section-btn:hover {
        background-color: #ffe21f;
    }

/* Scrollable container for large email lists */
.authorized-emails-list {
    max-height: 300px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 10px;
    margin-top: 10px;
}

/* Each email + remove button */
.authorized-email-entry {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.remove-email-btn {
    background-color: #ffc107;
    border: none;
    padding: 6px 10px;
    border-radius: 4px;
    cursor: pointer;
    color: #333;
    font-size: 12px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-left: 10px;
}

    .remove-email-btn:hover {
        background-color: #e0a800;
    }

/* Pre-auth form styling */
.pre-auth-form {
    margin-top: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 15px;
}

    .pre-auth-form textarea {
        width: 100%;
        min-height: 80px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px;
        font-size: 14px;
        margin-bottom: 10px;
        resize: vertical;
    }

    .pre-auth-form input[type="file"] {
        margin-bottom: 10px;
    }

    .pre-auth-form button {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        transition: background-color 0.3s ease;
    }

        .pre-auth-form button:hover {
            background-color: #0056b3;
        }
