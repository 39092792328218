.course-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

    .course-form h2 {
        margin-bottom: 20px;
        font-size: 24px;
        color: #333;
    }

.form-group {
    margin-bottom: 15px;
}

    .form-group label {
        display: block;
        font-weight: bold;
        color: #555;
        margin-bottom: 5px;
    }

    .form-group input,
    .form-group textarea {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .form-group textarea {
        resize: vertical;
        height: 100px;
    }

.form-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .form-actions button {
        padding: 10px 20px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .form-actions button[type="submit"] {
            background-color: #007bff;
            color: #fff;
        }

            .form-actions button[type="submit"]:hover {
                background-color: #0056b3;
            }

        .form-actions button[type="button"] {
            background-color: #ccc;
            color: #333;
        }

            .form-actions button[type="button"]:hover {
                background-color: #aaa;
            }

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}
