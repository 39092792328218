/* Templates.css */

/* Container for the template dashboard or form */
.templates-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f1f3f5;
}

/* Template list styles */
.template-list {
    margin-top: 20px;
}

    .template-list .template-item {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid #ddd;
        margin-bottom: 15px;
        cursor: pointer;
        transition: border-color 0.3s ease, box-shadow 0.3s ease;
    }

        .template-list .template-item:hover {
            border-color: #0056b3;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }

    .template-list h4 {
        margin: 0 0 10px;
        color: #333;
    }

    .template-list p {
        margin: 0;
        color: #555;
        font-size: 14px;
    }

/* Template form styles */
.template-form {
    background-color: #fff;
    padding: 25px;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

    .template-form input,
    .template-form textarea {
        width: 100%;
        padding: 12px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        transition: border-color 0.3s ease;
    }

        .template-form input:focus,
        .template-form textarea:focus {
            border-color: #007bff;
            outline: none;
        }

    .template-form label {
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
        color: #333;
    }

    .template-form button {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 12px 25px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;
    }

        .template-form button:hover {
            background-color: #0056b3;
        }

        .template-form button:disabled {
            background-color: #a0a5ab;
            cursor: not-allowed;
        }

/* Grid layout for selecting items in a template (case studies, ml kits) */
.template-resources-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
}

    .template-resources-grid .resource-card {
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        height: 120px; /* Uniform height for tiles */
        box-sizing: border-box;
    }

        .template-resources-grid .resource-card label {
            cursor: pointer;
            color: #333;
        }

.template-form h3 {
    margin-bottom: 15px;
    font-size: 20px;
    color: #333;
}

    .template-form h3.section-title {
        margin-top: 20px;
        font-size: 18px;
        color: #333;
    }

/* Media queries */
@media (max-width: 768px) {
    .template-resources-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}

@media (max-width: 480px) {
    .template-resources-grid {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
}


/* The container for each pair of columns */
.two-column-section {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

/* Each column */
.column {
    flex: 1;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
}

    /* Titles within each column */
    .column h4 {
        margin-top: 0;
    }

/* Items in each column (available or selected) */
.resource-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 8px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
}

    .resource-item span {
        margin-right: auto; /* So the text is left, button is right */
        padding-right: 8px;
    }