.course-list {
	margin: 20px 0;
	font-family: Arial, sans-serif;
}

.course-table {
	width: 100%;
	border-collapse: collapse;
	margin: 20px 0;
}

	.course-table th,
	.course-table td {
		border: 1px solid #ddd;
		padding: 8px;
		text-align: left;
	}

	.course-table th {
		background-color: #f4f4f4;
		font-weight: bold;
	}

	.course-table tr:nth-child(even) {
		background-color: #f9f9f9;
	}

	.course-table tr:hover {
		background-color: #f1f1f1;
	}

	.course-table button {
		padding: 5px 10px;
		background-color: #007bff;
		color: #fff;
		border: none;
		border-radius: 3px;
		cursor: pointer;
	}

		.course-table button:hover {
			background-color: #0056b3;
		}

.course-list h3,
.course-list h4 {
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 1.2em;
}
