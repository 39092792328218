/* TemplateDetails.css */

/* Main container for TemplateDetails */
.template-details {
    background-color: #fafafa; /* Subtle background */
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
}

    /* Title at the top of TemplateDetails */
    .template-details h2 {
        margin-top: 0;
        font-size: 1.8rem;
        color: #0056b3; /* A primary color (blue) */
        margin-bottom: 1rem;
    }

    /* Subheadings inside TemplateDetails (e.g., resource sections) */
    .template-details h3 {
        font-size: 1.4rem;
        margin-top: 1.2rem;
        color: #333;
    }

    .template-details h4 {
        font-size: 1.2rem;
        margin: 0.8rem 0;
        color: #555;
    }

    /* Paragraph text for descriptions, etc. */
    .template-details p {
        font-size: 0.95rem;
        color: #444;
        line-height: 1.4;
        margin-bottom: 0.8rem;
    }

/* Section that holds the resource lists (Case Studies, ML Tools) */
.resource-section {
    margin-top: 1rem;
    margin-bottom: 1.2rem;
}

    /* Basic UL for displaying resources */
    .resource-section ul {
        list-style-type: none;
        padding-left: 0;
        margin: 0.5rem 0;
    }

    .resource-section li {
        background-color: #fff;
        border: 1px solid #eee;
        border-radius: 6px;
        margin-bottom: 6px;
        padding: 8px;
        transition: background-color 0.3s ease;
        display: flex;
        align-items: center;
        font-size: 0.95rem;
    }

        .resource-section li:hover {
            background-color: #f7f7f7;
        }

        .resource-section li span {
            margin-right: auto;
        }

/* Button row near the bottom (Edit, Create Course, Close) */
.template-actions {
    margin-top: 1.5rem;
    display: flex;
    gap: 0.8rem;
}

    .template-actions button {
        border: none;
        border-radius: 4px;
        padding: 0.6rem 1.2rem;
        font-size: 0.95rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    /* Primary button (e.g., Edit) */
    .template-actions .primary-button {
        background-color: #007bff;
        color: #fff;
    }

        .template-actions .primary-button:hover {
            background-color: #0056b3;
        }

    /* Secondary button (e.g., Create Course) */
    .template-actions .secondary-button {
        background-color: #28a745;
        color: #fff;
    }

        .template-actions .secondary-button:hover {
            background-color: #218838;
        }

    /* Tertiary or 'Close' button (lighter) */
    .template-actions .close-button {
        background-color: #6c757d;
        color: #fff;
    }

        .template-actions .close-button:hover {
            background-color: #545b62;
        }

/* Course list container */
.course-list {
    margin-top: 1.5rem;
}

    /* Title above the course list */
    .course-list h3 {
        font-size: 1.2rem;
        margin-bottom: 0.8rem;
        color: #666;
    }

/* Each course item styling */
.course-item {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 10px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

    .course-item:hover {
        background-color: #f7f7f7;
    }

    .course-item h4 {
        margin: 0;
        color: #333;
        font-size: 1rem;
        margin-bottom: 0.3rem;
    }

    .course-item p {
        margin: 0;
        font-size: 0.9rem;
        color: #555;
    }

/* Placeholder "Create Course" or "Dummy" form styling */
.create-course-dummy {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 1rem;
    margin-top: 1rem;
}

    .create-course-dummy h3 {
        color: #007bff;
        margin-top: 0;
    }
