.profile-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

    .profile-page h2 {
        text-align: center;
        font-size: 2rem;
        color: #25d366;
        margin-bottom: 20px;
    }

.profile-details {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

    .profile-details p {
        font-size: 1.1rem;
        margin: 10px 0;
        color: #333;
    }

        .profile-details p strong {
            color: #25d366;
        }

/* Treat these as purchased courses container */
.purchased-bundles {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

    .purchased-bundles h3 {
        text-align: center;
        font-size: 1.5rem;
        color: #25d366;
        margin-bottom: 20px;
    }

/* We�re reusing .bundle-details class for each course item */
.bundle-details {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
    background-color: #eaf7e5;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
}

    .bundle-details p {
        font-size: 1rem;
        color: #333;
        margin: 8px 0;
    }

        .bundle-details p strong {
            color: #1a8d1a;
        }

.no-access-message {
    text-align: center;
    font-size: 1.2rem;
    color: #ff6f61;
}
