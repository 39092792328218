.admin-page {
  padding: 4rem 2rem; /* More padding for a spacious look */
  text-align: center;
  padding-top: 8rem;
  background-color: #f5f5f5; /* Light background color */
  min-height: 100vh; /* Ensure the page takes at least the full height of the viewport */
}

.admin-title {
  font-size: 2.5rem; /* Larger, bolder title */
  font-weight: bold;
  color: #25d366; /* Matching theme color */
  margin-bottom: 2rem; /* Space between title and buttons */
}

.admin-links {
  display: flex;
  flex-direction: column; /* Arrange links vertically */
  align-items: center; /* Center the links */
  gap: 1.5rem; /* Space between buttons */
}

.admin-link {
  display: block;
  padding: 1rem 3rem;
  background-color: #25d366;
  color: #fff;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1.25rem; /* Slightly larger font size for buttons */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for hover effect */
  width: 100%; /* Full width of the flex container */
  max-width: 300px; /* Ensure buttons don't get too wide */
  text-align: center; /* Center text inside the button */
}

.admin-link:hover {
  background-color: #1da153;
  transform: translateY(-3px); /* Slight lift on hover */
}

.admin-link:active {
  transform: translateY(0); /* Reset lift on click */
}
