/* General container */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #f7f7f7;
    text-align: center;
}

/* Login box */
.login-box {
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
}

.login-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
}

.login-message,
.login-error {
    margin-bottom: 1rem;
    font-size: 0.9rem;
    text-align: center;
}

.login-message {
    color: #333;
}

.login-error {
    color: red;
}

/* Sign-in button */
.login-button {
    background-color: #4285f4;
    color: white;
    padding: 12px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    max-width: 250px;
    transition: background-color 0.3s ease;
    margin: 10px auto;
}

    .login-button:hover {
        background-color: #357ae8;
    }

/* Responsive spacing for ThreeStepDiagram */
.three-step-container {
    width: 100%;
    max-width: 800px;
    margin-top: 40px;
    padding: 0 20px;
}
