/* General Footer Styles */
footer {
    background-color: #000; /* Black background */
    color: white; /* White text color */
    width: 100%; /* Full-width footer */
    box-sizing: border-box; /* Include padding and border in the element's width and height */
    padding: 1rem 0; /* Padding for spacing */
}

.footer-container {
    max-width: 1200px; /* Limit width for content alignment */
    margin: 0 auto; /* Center content */
    padding: 0 1rem; /* Padding on left and right */
}

/* Footer Header Styles */
.footer-header {
    display: flex; /* Flexbox layout for header */
    justify-content: center; /* Center logo */
    align-items: center; /* Center items vertically */
    padding: 0.5rem 0; /* Padding for top and bottom */
}

.footer-logo {
    font-size: 1.5rem; /* Font size for logo */
    font-weight: bold;
    color: #25d366; /* Green text for logo */
    margin: 0; /* Remove margin */
}

.footer-logo-highlight {
    color: white; /* White highlight for part of the logo */
}

/* Footer Content Styles */
.footer-content {
    margin-top: 1rem; /* Add margin between header and content */
}

.footer-columns {
    display: flex; /* Flexbox layout for columns */
    justify-content: space-between; /* Space out columns */
    gap: 1rem; /* Space between columns */
    padding: 0.5rem 0; /* Padding for top and bottom */
}

.footer-column {
    flex: 1; /* Columns expand equally */
    min-width: 150px; /* Prevent columns from becoming too narrow */
}

/* Section Headings */
.footer-heading {
    margin-bottom: 0.75rem; /* Space below headings */
    font-weight: bold; /* Bold headings */
    color: #25d366; /* Green color for section headings */
    font-size: 1.125rem; /* Adjusted font size */
}

/* Links */
footer ul {
    list-style: none; /* Remove default list styles */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}

footer li {
    margin-bottom: 0.5rem; /* Spacing below list items */
}

footer a {
    color: white; /* White text for links */
    text-decoration: none; /* Remove underline */
    transition: color 0.3s; /* Smooth transition on hover */
    font-size: 0.875rem; /* Smaller font size */
}

    footer a:hover {
        color: #25d366; /* Green color on hover */
    }

/* Responsive Design */
@media (max-width: 768px) {
    .footer-columns {
        flex-direction: column; /* Stack columns vertically on smaller screens */
    }

    .footer-column {
        margin-bottom: 1rem; /* Add spacing between stacked columns */
    }
}
.tm-symbol {
    font-family: 'Arial', sans-serif; /* Fallback font that supports � */
    font-size: inherit; /* Ensure the size matches the surrounding text */
    vertical-align: super; /* Position the � properly */
}