/* Container and Title */
.toolkit-management {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem;
    text-align: center;
    background-color: #f8f9fa;
    min-height: 100vh;
}

.toolkit-management-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #25d366;
    margin-bottom: 2rem;
}

/* Form Styling */
.mlkit-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 600px; /* Increased max-width for better visibility */
    padding: 1rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

    .mlkit-form .form-group {
        width: 100%;
        margin-bottom: 1.5rem;
        text-align: left;
    }

        .mlkit-form .form-group label {
            display: block;
            margin-bottom: 0.5rem;
            color: #333;
            font-weight: bold;
        }

    .mlkit-form textarea {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-sizing: border-box;
        resize: vertical;
    }


    .mlkit-form button {
        padding: 0.75rem 1.5rem;
        background-color: #25d366;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin: 0.5rem;
        width: 100%;
        max-width: 200px;
    }

        .mlkit-form button:hover {
            background-color: #1da153;
        }

/* Gallery View Styling */
.mlkits-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.mlkit-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

    .mlkit-item:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }

    .mlkit-item img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .mlkit-item h4 {
        font-size: 1.5rem;
        margin: 0.5rem;
        color: #25d366;
    }

    .mlkit-item p {
        padding: 0 1rem 1rem;
        font-size: 1rem;
        color: #666;
    }

    /* Buttons and Actions */
    .mlkit-item .actions,
    .mlkits-table .actions-column {
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        margin-top: 0.5rem;
    }

    .mlkit-item button,
    .mlkits-table td button {
        padding: 0.5rem 1rem;
        background-color: #ff0000;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin: 0.5rem 0;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

        .mlkit-item button:hover,
        .mlkits-table td button:hover {
            background-color: #cc0000;
        }

    .mlkit-item .actions button {
        height: 100%;
    }

/* Specific styling for the "Open Tool" button */
.open-toolkit-button {
    background-color: #25d366;
}

    .open-toolkit-button:hover {
        background-color: #1da153;
    }

/* Table & Drag-and-Drop Styling */
.mlkits-table {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border-collapse: collapse;
}

    .mlkits-table th, .mlkits-table td {
        padding: 1rem;
        border: 1px solid #ddd;
        text-align: left;
    }

.loading {
    font-size: 1.5rem;
    color: #007bff;
}

/* View Toggle Buttons */
.view-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

    .view-toggle button {
        padding: 0.5rem 1rem;
        margin: 0 0.5rem;
        border: none;
        background-color: #ddd;
        color: #333;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 4px;
    }

        .view-toggle button.active {
            background-color: #25d366;
            color: #fff;
        }

        .view-toggle button:hover {
            background-color: #1da153;
        }

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .mlkits-gallery {
        gap: 1rem; /* Reduce gap between items */
    }

    .mlkit-item {
        max-width: 100%; /* Make items full-width on smaller screens */
    }

    .mlkits-table {
        width: 100%;
        overflow-x: auto;
    }
}
