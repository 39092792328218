.instructor-dashboard {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f1f3f5;
}

.bundle-creators-dropdown {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

    .bundle-creators-dropdown label {
        font-weight: bold;
        color: #333;
    }

    .bundle-creators-dropdown select {
        padding: 8px;
        border-radius: 4px;
        border: 1px solid #ccc;
        font-size: 16px;
        background-color: #f9f9f9;
        cursor: pointer;
        transition: border-color 0.3s ease;
    }

        .bundle-creators-dropdown select:hover {
            border-color: #0056b3;
        }

.instructor-dashboard h2 {
    color: #0056b3;
    margin-bottom: 20px;
}

.bundle-form {
    background-color: #fff;
    padding: 25px;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

    .bundle-form input,
    .bundle-form textarea {
        width: 100%;
        padding: 12px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        transition: border-color 0.3s ease;
    }

        .bundle-form input:focus,
        .bundle-form textarea:focus {
            border-color: #007bff;
            outline: none;
        }

    .bundle-form label {
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
        color: #333;
    }

    .bundle-form button {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 12px 25px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;
    }

        .bundle-form button:hover {
            background-color: #0056b3;
        }

        .bundle-form button:disabled {
            background-color: #a0a5ab;
            cursor: not-allowed;
        }

.bundle-list {
    margin-top: 20px;
}

    .bundle-list .bundle-item {
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid #ddd;
        margin-bottom: 15px;
        cursor: pointer;
        transition: border-color 0.3s ease, box-shadow 0.3s ease;
    }

        .bundle-list .bundle-item:hover {
            border-color: #0056b3;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }

    .bundle-list h4 {
        margin: 0 0 10px;
        color: #333;
    }

    .bundle-list p {
        margin: 0;
        color: #555;
        font-size: 14px;
    }

.tabs {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
}

    .tabs button {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease;
    }

        .tabs button:hover {
            background-color: #0056b3;
        }

        .tabs button.active {
            background-color: #0056b3;
        }

        .tabs button:focus {
            outline: none;
        }
