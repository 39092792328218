/* AdminInstructorRequests.css */

.admin-instructor-requests {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.admin-instructor-requests h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-align: center;
}

.requests-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.requests-table th, .requests-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.requests-table th {
  background-color: #f2f2f2;
}

.approve-btn, .decline-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.approve-btn {
  background-color: #4CAF50;
  color: white;
  margin-right: 5px;
}

.decline-btn {
  background-color: #f44336;
  color: white;
}
