/* MLKits.css */

.mlkits-page {
    width: 100%;
    max-width: none;
    margin: 0 auto;
    padding: 16px;
}

    .mlkits-page h2 {
        text-align: center;
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 24px;
        color: #25d366;
    }

    .mlkits-page .mlkits-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 24px;
        padding: 0 16px;
    }

    .mlkits-page .mlkit-item {
        background-color: #f9f9f9;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 16px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start; /* Ensure content stacks from top to bottom */
        height: 100%;
        cursor: pointer;
    }

        .mlkits-page .mlkit-item.expanded {
            padding: 24px;
        }

        .mlkits-page .mlkit-item:hover {
            transform: translateY(-5px);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
        }

        .mlkits-page .mlkit-item img {
            max-width: 100%;
            border-radius: 8px;
            margin-bottom: 16px; /* Adds space between image and title */
        }

        .mlkits-page .mlkit-item h4 {
            font-size: 1.25rem;
            font-weight: bold;
            color: #333;
            margin-bottom: 8px;
            text-align: center;
            margin-top: 0; /* Ensure the title is right below the image */
        }

        .mlkits-page .mlkit-item p {
            font-size: 1rem;
            color: #555;
            text-align: left; /* Justifies the description text */
            margin-bottom: 16px;
            flex-grow: 1; /* Ensures the text grows to fill space */
        }

    /* Disabled ML kits */
    .mlkits-page .mlkit-disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .mlkits-page .mlkit-btn-disabled {
        background-color: #cccccc;
        color: #666666;
        cursor: not-allowed;
        pointer-events: none;
    }

    /* Static, horizontally aligned button with fixed vertical dimensions */
    .mlkits-page .mlkit-btn {
        background-color: #25d366;
        color: #fff;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: bold;
        padding: 12px 24px;
        width: 100%; /* Ensures the button takes up full width */
        max-width: 300px; /* Ensures the button doesn't grow too large */
        height: 48px; /* Fixed height for all buttons */
        max-height: 48px;
        line-height: 24px; /* Ensures consistent vertical alignment of text */
        margin-top: 16px; /* Space between the button and the content */
        cursor: pointer;
        transition: background-color 0.3s ease;
        text-align: center; /* Ensures the text is centered horizontally */
        align-self: center; /* Align button in the center horizontally */
    }

        .mlkits-page .mlkit-btn:hover {
            background-color: #1fba59;
        }

    /* Button style for disabled state */
    .mlkits-page .mlkit-btn-disabled {
        background-color: #cccccc;
        color: #666666;
        cursor: not-allowed;
        pointer-events: none;
        height: 48px; /* Fixed height for disabled buttons as well */
        max-height: 48px;
        line-height: 24px; /* Consistent text alignment */
    }

    .mlkits-page .mlkit-viewer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .mlkits-page .mlkit-iframe {
        width: 100%; /* Full width of the container */
        height: calc(100vh - 80px); /* Adjusted to fit below a 80px button/header or other elements */
        border-radius: 12px;
        overflow: auto; /* Allow the iframe to scroll if needed */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-top: 16px;
        border: none; /* Optional: Remove default border for a cleaner look */
    }

        .mlkits-page .mlkit-iframe iframe {
            width: 100%;
            height: 100vh;
            border: none;
        }

    .mlkits-page .back-to-list {
        display: inline-block;
        padding: 12px 24px;
        background-color: #25d366;
        color: #fff;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .mlkits-page .back-to-list:hover {
            background-color: #1fba59;
        }
