/* Container for diagram */
.three-step-diagram-container {
    padding: 20px;
    text-align: center;
    background-color: #f4f4f4;
    margin: 0 auto;
    width: 100%;
}

/* Diagram title */
.diagram-title {
    font-size: 2rem;
    color: #25d366;
    margin-bottom: 30px;
    font-weight: bold;
}

/* Grid for steps */
.steps-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 20px;
}

/* Step card styling */
.step-card {
    background-color: white;
    border: 2px solid #25d366;
    border-radius: 10px;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
}

    .step-card:hover {
        transform: translateY(-10px);
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    }

/* Icon styles */
.step-icon {
    font-size: 2rem;
    color: #25d366;
    margin-bottom: 15px;
}

/* Title and description */
.step-title {
    font-size: 1.5rem;
    color: #25d366;
    font-weight: bold;
    margin-bottom: 10px;
}

.step-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
}
