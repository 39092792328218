.case-studies-page {
    width: 100%;
    max-width: none;
    margin: 0 auto;
    padding: 16px;
    padding-top: 3rem;
    text-align: center;
}

.case-studies-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
    padding: 0 16px;
}

.case-study-item {
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    cursor: pointer;
}

    .case-study-item:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    }

    .case-study-item img {
        width: 100%;
        height: 180px; /* Fixed height for consistency */
        object-fit: cover; /* Maintain aspect ratio and cover the area */
        border-radius: 8px;
        margin-bottom: 16px;
    }

    .case-study-item h4 {
        font-size: 1.25rem;
        font-weight: bold;
        color: #333;
        margin-bottom: 8px;
        text-align: center;
        margin-top: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .case-study-item p {
        font-size: 1rem;
        color: #555;
        text-align: left;
        margin-bottom: 8px;
        flex-grow: 1; /* Ensures content grows and pushes button down */
        max-height: 4rem; /* Limit description height */
        overflow: hidden; /* Hide overflow for long descriptions */
        text-overflow: ellipsis; /* Add ellipsis for long descriptions */
        line-height: 1.4rem;
        position: relative;
    }

.tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
    justify-content: center;
}

.tag {
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 0.875rem;
}

.case-study-btn {
    background-color: #25d366;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    padding: 12px 24px;
    width: 100%;
    max-width: 300px;
    height: 48px; /* Fixed height */
    line-height: 24px; /* Ensures consistent vertical alignment of text */
    margin-top: 16px; /* Space between the button and the content */
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    align-self: center;
}

    .case-study-btn:hover {
        background-color: #1fba59;
    }

.case-study-btn-disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

.case-study-details {
    text-align: left;
    margin: 0 auto;
    padding: 2rem;
    max-width: 800px;
}

    .case-study-details h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
        color: #25d366;
    }

    .case-study-details p {
        margin-bottom: 1.5rem;
        color: #333;
    }

.case-study-documents {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
}

.pdf-documents ul,
.supplemental-documents ul {
    list-style-type: none;
    padding-left: 0;
}

.case-study-documents .file-columns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.case-study-documents .file-column {
    width: 45%;
}

.pdf-icon,
.supplemental-icon {
    color: #007bff;
    margin-right: 8px;
}

.case-study-documents li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

    .case-study-documents li a {
        text-decoration: none;
        color: #007bff;
        transition: color 0.2s ease;
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

        .case-study-documents li a:hover {
            color: #0056b3;
        }

.case-study-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.back-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #25d366;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

    .back-button:hover {
        background-color: #1fba59;
    }
