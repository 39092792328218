/* Navbar Styling */
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 40;
    background-color: black;
    height: 4rem;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 1rem;
    color: white;
    width: 100%;
}

/* Title Styling */
.navbar-title {
    font-size: 1.25rem; /* Slightly smaller font size for mobile */
    font-weight: bold;
    color: #25d366;
    white-space: nowrap;
}

    .navbar-title span {
        color: white;
        transition: color 0.3s;
    }

    .navbar-title:hover span {
        color: #25d366;
    }

/* TM Symbol */
.tm-symbol {
    font-family: 'Arial', sans-serif;
    font-size: inherit;
    vertical-align: super;
    margin-left: 2px;
}

/* Navbar Toggle (Hamburger Menu) */
.navbar-toggle {
    display: block;
    cursor: pointer;
    z-index: 50;
}

@media (min-width: 768px) {
    .navbar-toggle {
        display: none;
    }
}

/* Submenu Accessibility */
.navbar-links {
    display: none;
}

@media (min-width: 768px) {
    .navbar-links {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        padding: 0;
        gap: 1rem;
    }
}

.navbar-link-item {
    padding: 0.5rem 0;
    position: relative;
    font-size: 1.25rem;
    color: white;
    transition: color 0.3s;
}

    .navbar-link-item:hover {
        color: #25d366;
    }

    .navbar-link-item a {
        text-decoration: none;
        color: inherit;
    }

/* Dropdown Submenu */
.navbar-submenu {
    display: none;
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;
    padding: 0.5rem 0;
    white-space: nowrap;
}

.navbar-link-item:hover .navbar-submenu {
    display: block;
}

.navbar-submenu-item {
    padding: 0.5rem 1rem;
}

    .navbar-submenu-item a {
        text-decoration: none;
        color: black;
        transition: color 0.3s;
    }

    .navbar-submenu-item:hover {
        background-color: #f3f3f3;
        color: #25d366;
    }

/* Mobile Navbar */
.mobile-navbar {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: black;
    overflow-y: auto; /* Allow scrolling for long content */
    transition: left 0.5s;
    z-index: 40;
}

    .mobile-navbar.open {
        left: 0;
    }

.mobile-navbar-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #25d366;
    padding: 1rem;
    text-align: center;
}

.mobile-navbar-links {
    list-style: none;
    padding: 1rem;
    margin: 0;
}

.mobile-navbar-link-item {
    padding: 1rem;
    border-bottom: 1px solid #666;
    color: white;
    text-align: center;
    transition: background-color 0.3s;
}

    .mobile-navbar-link-item:hover {
        background-color: #333;
    }

.mobile-dropdown {
    display: none;
    background-color: #1a1a1a;
    padding: 0.5rem 1rem;
}

.mobile-navbar-link-item.submenu-open .mobile-dropdown {
    display: block;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 40;
    transition: background-color 0.3s;
    background-color: black;
    height: 4rem;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 1rem;
    color: white;
}

.navbar-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #25d366;
    flex-shrink: 0; /* Prevent logo from shrinking */
}

.navbar-links-wrapper {
    display: flex;
    flex-grow: 1; /* Allow links to take up remaining space */
    justify-content: flex-end; /* Align links to the right */
}

.navbar-loading {
    display: none;
    align-items: center;
    justify-content: center;
}

@media (min-width: 768px) {
    .navbar-loading {
        display: flex;
    }
}

.navbar-toggle {
    display: block;
    cursor: pointer;
}

@media (min-width: 768px) {
    .navbar-toggle {
        display: none;
    }
}

.tm-symbol {
    font-family: 'Arial', sans-serif;
    font-size: inherit;
    vertical-align: super;
    margin-left: 2px;
}