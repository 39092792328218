.bundle-purchase-container {
  max-width: 1240px;
  margin: 90px auto;
  padding: 16px;
}

.bundle-code-input-section {
  text-align: center;
}

.bundle-code-input {
  border: 2px solid #d3d3d3;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 300px;
}

.submit-bundle-code {
  background-color: #25d366;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
}

.bundle-details {
  margin-top: 40px;
  text-align: center;
}

.bundle-details h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.bundle-details p {
  color: #4a4a4a;
  margin: 8px 0;
}

.purchase-bundle {
  background-color: black;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  margin-top: 24px;
  transition: background-color 0.3s;
}

.purchase-bundle:hover {
  background-color: #333333;
}
