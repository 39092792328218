.hero-page-container {
    font-family: 'Arial', sans-serif;
    background-color: #000300;
    color: #fff;
    position: relative;
}

/* Hero section */
.hero-section {
    background-color: #000300;
    color: #25d366;
    text-align: center;
    padding: 100px 20px;
}

    .hero-section h1 {
        font-size: 60px;
        font-weight: bold;
    }

    .hero-section p {
        font-size: 20px;
        margin: 20px 0;
    }


/* Scroll Indicator Section (both arrow and text together) */
.scroll-indicator {
    position: fixed; /* Stay at the bottom of the viewport */
    bottom: 20px; /* 20px from the bottom of the viewport */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center properly */
    display: flex; /* Flexbox layout for text and arrow */
    flex-direction: column;
    align-items: center; /* Center text and arrow together */
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    padding: 15px 30px; /* Larger padding for better visibility */
    border-radius: 12px; /* Rounded corners */
    z-index: 100; /* Ensure it stays above other content */
    opacity: 0.9;
    transition: opacity 0.3s ease-in-out; /* Smooth fade-out effect */
    text-align: center;
}

    .scroll-indicator p {
        font-size: 24px; /* Bigger text */
        color: #fff;
        margin: 0;
    }

.scroll-arrow {
    font-size: 36px; /* Bigger arrow */
    color: #25d366;
    margin-top: 10px; /* Space between text and arrow */
    animation: bounce 2s infinite; /* Bounce animation */
}

/* Bounce animation for the arrow */
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-15px); /* Stronger bounce */
    }

    60% {
        transform: translateY(-7px);
    }
}

/* Hide the scroll indicator when at the bottom */
.hidden {
    opacity: 0;
    visibility: hidden;
}

/* Why StatLeap section */
.why-stat-leap-section {
    background-color: #f8f8f8;
    padding: 80px 20px;
}

/* Three Step Diagram Section */
.three-step-section {
    background-color: #ffffff;
    padding: 60px 20px;
}

/* ML Kits Promo Section */

.mlkits-title {
    font-size: 36px;
    color: #25d366;
    margin-bottom: 10px;
}

.mlkits-description {
    font-size: 18px;
    color: #555;
    max-width: 800px;
    margin-bottom: 30px;
}

.mlkits-promo-section {
    background-color: #f9f9f9;
    padding: 80px 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensures everything is centered horizontally */
    justify-content: center;
    text-align: center;
}

.mlkits-screenshot-link {
    display: flex; /* Ensures the anchor takes up only the space of the image */
    justify-content: center; /* Centers the image horizontally within the anchor */
    width: 100%; /* Makes sure the image wrapper is responsive */
}

.mlkits-screenshot {
    max-width: 100%;
    width: 70%; /* Limits the width to 70% of the container */
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.mlkits-cta-container {
    margin-top: 20px;
}

.mlkits-cta-button {
    background-color: #25d366;
    color: #fff;
    padding: 12px 25px;
    font-size: 18px;
    border-radius: 30px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

    .mlkits-cta-button:hover {
        background-color: #1eaa56;
        transform: scale(1.05);
    }

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .mlkits-screenshot {
        width: 85%; /* Adjust width for smaller screens */
    }

    .mlkits-title {
        font-size: 30px;
    }

    .mlkits-description {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .mlkits-screenshot {
        width: 95%; /* Adjust width for mobile screens */
    }

    .mlkits-title {
        font-size: 26px;
    }

    .mlkits-description {
        font-size: 15px;
    }

    .mlkits-cta-button {
        font-size: 16px;
        padding: 10px 20px;
    }
}

/* How It Works section */
.how-it-works-section {
    background-color: #ffffff;
    padding: 80px 20px;
}

    .how-it-works-section h2 {
        font-size: 36px;
        color: #25d366;
    }

    .how-it-works-section .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

/* Benefits section */
.benefits-section {
    background-color: #f1f1f1;
    padding: 80px 20px;
}

/* Footer section */
.footer-container {
    background-color: #000300;
    color: #fff;
    padding: 40px 20px;
}
