.user-management-container {
    padding: 6rem 2rem 4rem 2rem; /* Adjusting top padding to ensure content appears below the navbar */
    text-align: center;
    background-color: #f8f9fa; /* Light background color */
    min-height: 100vh; /* Ensure the page takes at least the full height of the viewport */
}

/* View mode selector */
.view-mode-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 2rem; /* Add spacing from the top for visibility */
}

.view-toggle-label {
    margin-right: 2rem;
    font-size: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}

    .view-toggle-label input {
        margin-right: 0.5rem;
    }

    .view-toggle-label:hover {
        color: #007bff;
    }

/* User management title */
.user-management-title {
    font-size: 2.5rem; /* Larger, bolder title */
    font-weight: bold;
    color: #25d366; /* Matching the theme color */
    margin-bottom: 2rem; /* Space between title and table */
}

/* CSV Export Buttons at the Top */
.csv-export-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1.5rem; /* Spacing between export buttons and the rest of the content */
}

    .csv-export-buttons .export-button {
        padding: 0.75rem 1.5rem;
        background-color: #007bff; /* Consistent with pagination button color */
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .csv-export-buttons .export-button:hover {
            background-color: #0056b3; /* Darker blue on hover */
        }

/* User table */
.user-table {
    width: 100%;
    border-collapse: collapse;
    margin: 2rem 0; /* Increased margin for better spacing */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

    .user-table th,
    .user-table td {
        border: 1px solid #ddd;
        padding: 0.75rem; /* Increased padding for better spacing */
        text-align: left; /* Align text to the left for readability */
    }

    .user-table th {
        background-color: #007bff; /* Brighter background for header */
        color: #000; /* White text for contrast */
        cursor: pointer; /* Pointer cursor to indicate sorting capability */
    }

        .user-table th:hover {
            background-color: #0056b3; /* Darker blue on hover */
        }

    .user-table td {
        background-color: #fff; /* Ensure rows have a white background */
    }

    .user-table tr:nth-child(even) td {
        background-color: #f4f4f4; /* Alternate row color for readability */
    }

    .user-table td button {
        padding: 0.4rem 0.8rem; /* Slightly larger padding for buttons */
        background-color: #28a745; /* Bootstrap success green for consistency */
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease; /* Smooth transition on hover */
    }

        .user-table td button:hover {
            background-color: #218838; /* Darker green on hover */
        }

/* Role section */
.user-role-section {
    margin-bottom: 3rem; /* Spacing between different role sections */
}

    .user-role-section h3 {
        color: #343a40; /* Darker text color for section titles */
        margin-bottom: 1rem;
    }

    .user-role-section input[type="text"] {
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
    }

        .user-role-section input[type="text"]:focus {
            border-color: #007bff; /* Border color on focus */
            outline: none;
        }

/* Sorting buttons in table headers */
.user-table th button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
}

    .user-table th button:hover {
        text-decoration: underline;
    }

/* Back to top button */
.back-to-top {
    margin: 2rem 0;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

    .back-to-top:hover {
        background-color: #0056b3;
    }

/* Pagination controls */
.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
}

    .pagination-controls select {
        padding: 0.4rem;
        margin-left: 0.5rem;
    }

/* Pagination buttons */
.pagination-buttons {
    display: flex;
    align-items: center;
}

    .pagination-buttons button {
        padding: 0.4rem 0.8rem;
        margin: 0 0.5rem;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .pagination-buttons button:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }

    .pagination-buttons span {
        margin: 0 0.5rem;
    }
