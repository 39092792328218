html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensures the App component covers the full height of the viewport */
}

.navbar + .content-wrapper {
    margin-top: 2.7rem; /* Adjust this value to match the navbar height */
}

.content-wrapper {
    flex: 1; /* Takes up all available space, pushing the footer down */
    padding-bottom: 1rem; /* Optional: Add padding to the bottom if you want space above the footer */
    width: 100%; /* Ensure content-wrapper takes the full width */
}

footer {
    background-color: #333; /* Black or dark background color */
    color: white;
    text-align: center;
    padding: 1rem;
    width: 100%; /* Ensure the footer takes the full width of the page */
    margin-top: auto; /* Pushes the footer to the bottom of the container */
    box-sizing: border-box; /* Include padding in the element's width and height */
}
